
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ScrollToTop from './modules/ScrollToTop';
import Home from './pages/Home';
import Header from './assets/header';
import Footer from './assets/footer';
import ToolCheck from './pages/ToolCheck';
import Guide from './pages/Guide';
import Tool from './pages/Tool';
import Bundle from './pages/Bundle';
import Imprint from './pages/imprint';
import UnderConstruction from './modules/UnderConstruction';
import NotFound from './pages/NotFound';
import CookieConsent from './modules/CookieConsent';

function App() {

  return (
    <BrowserRouter>
      <main>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Guide" element={<Guide />} />
          <Route path="ToolCheck" element={<ToolCheck />} />
          <Route path="ToolCheck/Tool/:id" element={<Tool />} />
          <Route path="ToolCheck/Bundle/" element={<Bundle />} />
          <Route path="ToolCheck/Bundle/:id" element={<Bundle />} />
          <Route path="imprint" element={<Imprint />} />
          <Route path="under_construction" element={<UnderConstruction />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <CookieConsent
          acceptBtnText="ACCEPT"
          declineBtnText="DECLINE"
          BannerText={
            <>
              <h2>COOKIE NOTICE</h2>
              <p>This Site uses cookies and similar technologies, including third-party cookies,
                to function properly, perform statistical analysis and offer you a better experience. Consult the <Link to="/imprint" style={{ color: 'white' }}>Privacy Policy</Link> to find out more, to know which cookies are used and how to disable them and/or withhold your consent.</p>
              <p>By clicking on "Accept" you consent to all cookies. Otherwise the cookies for which consent is required will not be sotored on your device.</p>
            </>}
        >
        </CookieConsent>

      </main>
    </BrowserRouter >
  );
}

export default App;
