import React from 'react';
import './css/doubleImage.css';

const doubleImage = ({ width_, height_, imgsource1, imgsource2 }) => {
    return (
        <div className='doubleImage' style={{ width: width_, height: height_ }}>
            <img src={imgsource1} className="doubleImage-bottom" alt="Bild1" style={{ width: width_, height: height_ }} />
            <img src={imgsource2} className="doubleImage-top" alt="Bild2" style={{ width: width_, height: height_ }} />
        </div >
    );
};

export default doubleImage;
