export const bundles = [
    {
        id: 1,
        name: 'Watch hand tool kit',
        imageUrl: '/img/Bundles/bundle1.webp',
        contains: ['2', '10', '12', '16'],
        AliLink: 'https://s.click.aliexpress.com/e/_Dlzjh4r'

    },
    {
        id: 2,
        name: 'Watch strap tool kit',
        imageUrl: '/img/Bundles/bundle2.webp',
        contains: ['9', '11', '12', '14', '15'],
        AliLink: 'https://s.click.aliexpress.com/e/_DdhhF7l'
    },
    {
        id: 3,
        name: 'Watch tool multi kit',
        imageUrl: '/img/Bundles/bundle3.webp',
        contains: ['1', '2', '12', '4', '16'],
        AliLInk: 'https://s.click.aliexpress.com/e/_DlrbBjz'
    },
    {
        id: 4,
        name: 'Case opener kit',
        imageUrl: '/img/Bundles/bundle4.webp',
        contains: ['9', '12', '14', '15'],
        AliLink: 'https://s.click.aliexpress.com/e/_DC40Jdv'
    },
    {
        id: 5,
        name: 'Watch ultimate kit',
        imageUrl: '/img/Bundles/bundle5.webp',
        contains: ['8', '9', '10', '11', '13', '14', '15'],
        AliLink: 'https://s.click.aliexpress.com/e/_DlTLsPh'
    }
];