import React from 'react';

function HoverVideo({ sauce, width_ }) {
    const videoRef = React.useRef(null);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };
    const handleMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };
    return (
        <video
            ref={videoRef}
            width={width_}
            height="auto"
            onMouseEnter={handlePlay}
            onMouseLeave={handleMouseLeave}
            muted
        >
            <source src={sauce} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
}
export default HoverVideo;