import './css/imprint.css';

export default function Imprint() {
    return (
        <div>
            <div className="Imprint-Main">
                <h3>Legal notice</h3>
                <li>Adrian Pietsch</li>
                <li>Werner-Sombart-Straße 37</li>
                <li>78464 Konstanz</li>
                <li>Deutschland</li>
                <h4>Contact</h4>
                <p>modwatchers@gmail.com</p>
            </div>
            <div id="Privacy-Policy">
                <h2>Privacy Policy</h2>
                <p>This document outlines how personal information is collected, used, and processed
                    by the provider of this service (refer to contact details). The legal basis for data
                    protection is established by the General Data Protection Regulation (GDPR), the German
                    Federal Data Protection Act (BDSG), and the German Telecommunications-Telemedia Data
                    Protection Act (TDDDG). The provider is responsible for all matters related to data
                    protection. Please note that the hardware and software used in connection with this
                    website may have security vulnerabilities, making it impossible to guarantee complete protection against unauthorized access by third parties. We will not share your personal
                    information stored in our system with third parties unless required by law.</p>
                <h4>Your Rights</h4>
                <p>
                    You have the right to withdraw your consent to the processing of your personal data at any time,
                    as well as to object to the processing of your data for purposes such as direct marketing, market research,
                    and opinion polling. Additionally, you are entitled to have any incorrect personal data corrected and to request the deletion
                    of your personal data, provided no legal obligations require us to retain it. You may also request a restriction on the processing
                    of your personal data. Furthermore, you have the right to receive the personal data we process with your consent through automated
                    means in a commonly used, machine-readable format. To exercise any of these rights, please reach out to us (see contact details).
                </p>
                <h4>Legal basis of data processing</h4>
                <p>
                    The following information pertains to the processing of personal data, as outlined in this privacy policy,
                    for users accessing our service from within the European Economic Area (EEA): The legal basis for storing information on the user's
                    device and retrieving it is consent, in accordance with German law TDDDG § 25 para. 1. Under German law TDDDG § 25 para.
                    2 No. 2, consent is not necessary if the storage or access to information is essential for the provider to deliver
                    a service explicitly requested by the user. Details on which processing activities are considered absolutely necessary and do
                    not require consent can be found in the consent dialog.

                    For subsequent processing of personal data, the legal basis is derived from the GDPR: When we request your consent, the legal basis for processing
                    is GDPR article 6(1)(a). In other cases, we process personal data based on our legitimate interests
                    (such as detecting and correcting errors in our service, enhancing user-friendliness and performance, ensuring the economic operation
                    of our service, and protecting against attacks, sabotage, manipulation, misappropriation, and their legal prosecution)
                    in accordance with GDPR article 6(1)(f).
                </p>
                <h4>Links to external websites</h4>
                <p>
                    Our service may contain links to external websites provided by third parties. When you open such a link,
                    your browser will connect to the linked website in order to load and display it. During this process, data
                    such as your IP address, information regarding your browser and the address of the page you're currently
                    visiting (referrer URL) will be transmitted. The third party provider will be able to see that you reached
                    his website by following a link from our service. We have no control over the collection or processing of personal
                    data carried out by these third parties.
                </p>
                <h4>Cookies</h4>
                <p>Our service may store cookies on your device. Cookies contain a small amount of information that is sent to the server with each request.
                    They may be employed for reasons of user experience (e.g. storing log-in data in order to prevent having to log-in again for each visit).
                    Further, they may enable statistical analysis of the use of our service and, based on that, improvements to the service. Third-party content
                    that may be embedded in the service may also store cookies (e.g. videos, social media components or advertisements). You can influence the use
                    of cookies. Most browsers provide an option that restricts or completely prevents the use of cookies. This may prevent our service from functioning
                    as intended.</p>
                <h4>Google Analytics</h4>
                <p> Our website uses Google Analytics to help us understand how users interact with our site. Google Analytics uses cookies to collect information such as your IP address, which will be anonymized before being stored by Google. This means that your full IP address is not collected or stored. The anonymized data helps us analyze website traffic and improve our services.
                    If you have accepted the use of cookies on our website, we may use this anonymized data to improve your experience. If you decline cookies, certain tracking functionalities, including Google Analytics, may be disabled.</p>
                <h4>Your Rights</h4>
                <p>Depending on your location, you may have certain rights regarding your personal data, including the right to access, correct, delete, or object to the processing of your personal data. If you wish to exercise any of these rights, please contact us at modwatchers@gmail.com</p>
                <h4>Partner Programms</h4>
                <h5>Amazon</h5>
                <p>We are participants in the Amazon Partner Program. If you click on an Amazon affiliate
                    link within our service and make a purchase on Amazon, we may receive a
                    commission from Amazon. To track these purchases, Amazon uses cookies that allow them
                    to identify when you have clicked one of our affiliate links. For more information on
                    how Amazon handles data, please visit: https://www.amazon.com/gp/help/customer/display.html?nodeId=468496.
                </p>
                <h5>AliExpress</h5>
                <p>
                We are participants in the AliExpress Partner Program. If you click on an AliExpress affiliate
                    link within our service and make a purchase on AliExpress, we may receive a
                    commission from AliExpress. To track these purchases, AliExpress uses cookies that allow them
                    to identify when you have clicked one of our affiliate links. 
                </p>

            </div>

        </div>
    );
}