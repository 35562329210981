import './css/Tool.css';
import React from 'react';
import { useParams, Link, NavLink } from 'react-router-dom';
import { tool } from '../data/tools';
import ReactGA from 'react-ga4';

const verfolgeButton = () => {
    ReactGA.event({
        category: 'User',
        action: 'Clicked Button_Tool',
        label: 'Tool Link'
    });
};

export default function Tool() {
    const { id } = useParams();
    const item = tool.find(item => item.id === parseInt(id));

    if (!item) {
        return <div className="Tool-NotExists"><p>The tool you are looking for does not exist.</p> <Link style={{ color: "white" }} to="/Toolcheck/"> Bring me back</Link></div>;
    }

    return (
        <div className="Tool-Body">
            <Link style={{ textDecoration: 'none' }} to={`/Toolcheck/`}>
                <div className="Tool-Backarrow"><i class="fa-solid fa-arrow-left" style={{ color: '#ffffff' }}></i>   Go Back</div>
            </Link>
            <div className="Tool-Body-CenterWrap">
                <div className="Tool-Body-Wrapper">
                    <div className="Tool-Image">
                        <img src={item.imageUrl} alt={item.name} />
                    </div>
                    <div className='Tool-Divider'></div>
                    <div className="Tool-RightText">
                        <div>
                            <h2>{item.name}</h2>
                        </div>
                        <div className="Tool-NoHeading">
                            <div className="Tool-BuyButtons">
                                <div>
                                    <p>Available at:</p>
                                </div>
                                <div>
                                    <NavLink to={item.linkAlibaba} style={{ textDecoration: 'none', color: 'white' }} onClick={verfolgeButton}><div>AliExpress&nbsp;*</div></NavLink>
                                    <NavLink to={item.linkAmazon} style={{ textDecoration: 'none', color: 'white' }} onClick={verfolgeButton}><div>Amazon&nbsp;*</div></NavLink>
                                    {/* <div>Other</div> */}
                                </div>
                            </div>
                            <div className="Tool-Bundleoption">
                                <Link to={`../../ToolCheck/Bundle/${item.id}`} style={{ color: 'white', textDecoration: 'none' }}>
                                    <p>View bundle</p>
                                </Link>
                            </div>
                            <div className="Tool-BottomText">
                                <p>" {item.description} "</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Tool-RefNote">
                <p style={{color: 'white'}}>*This is an affiliate link. If you make a purchase through these links, I may earn a small commission at no extra cost to you</p>
            </div>
        </div>
    );

}