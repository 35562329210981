const ImageGrid =({ images }) => {

    return(
        <div className="ImageGrid-main">
            {images.map((image, index) =>(
                <div className="ImageGrid-Container">
                <img 
                key={index}
                src={image.src}
                alt={image.alt}
                className="ImageGrid-Item"
                />
                <p>{image.alt}</p>
                </div>
            ))}

        </div>
    );

};

export default ImageGrid;