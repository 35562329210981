import React, { useState, useEffect } from 'react';
import './css/GuideCarousel.css';

const GuideCarousel = ({ slides }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animation, setAnimation] = useState('');

    useEffect(() => {
        // Preload the next and previous images
        const preloadImage = (index) => {
            if (index >= 0 && index < slides.length) {
                const img = new Image();
                img.src = slides[index].image;
            }
        };

        // Preload next and previous images
        preloadImage(currentIndex + 1);
        preloadImage(currentIndex - 1);
    }, [currentIndex, slides]);

    const handleNext = () => {
        if (currentIndex < slides.length - 1) {
            setAnimation('fade-out');
            setTimeout(() => {
                setCurrentIndex((prevIndex) => prevIndex + 1);
                setAnimation('fade-in');
                setTimeout(() => setAnimation(''), 100);
            }, 100);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setAnimation('fade-out');
            setTimeout(() => {
                setCurrentIndex((prevIndex) => prevIndex - 1);
                setAnimation('fade-in');
                setTimeout(() => setAnimation(''), 100);
            }, 100);
        }
    };

    return (
        <div className="Guide-Carousel-Wrapper">
            <div className={`Guide-section4-Carousel ${animation}`}>
                <div className="Guide-section4-Carousel-Bigimg">
                    <img className="YDropShadow" src={slides[currentIndex].image} alt="Carousel" />
                </div>
                <div className="Guide-section4-Carousel-Text YDropShadow">
                    <div className="Guide-section4-Carousel-Text-Wrapper">
                        <p>{slides[currentIndex].text}</p>
                    </div>
                </div>
            </div>
            <div className="Guide-section4-Carousel-Arrows">
                <div
                    className={`Guide-sec4-Carousel-LArrow ${currentIndex === 0 ? 'Guide-BTN-Disabled' : ''}`}
                    id="Guide-Arrow"
                    onClick={handlePrev}
                >
                    <i className="fa-solid fa-arrow-left"></i>
                </div>
                <div className="Guide-MidProgress">
                    <p>{currentIndex + 1} / {slides.length}</p>
                </div>
                <div
                    className={`Guide-sec4-Carousel-RArrow ${currentIndex === slides.length - 1 ? 'Guide-BTN-Disabled' : ''}`}
                    id="Guide-Arrow"
                    onClick={handleNext}
                >
                    <i className="fa-solid fa-arrow-right"></i>
                </div>
            </div>
        </div>
    );
};

export default GuideCarousel;
