import ImageSlider from '../modules/ImageSlider';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import confetti from 'canvas-confetti';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import './css/Home.css';
import '../modules/css/ImageGrid.css';
import ImageGrid from '../modules/ImageGrid.js';

export default function Home() {

    const images = [
        { src: './img/watchShots/finemods2_mod.webp', alt: 'Mod by u/finemods' },
        { src: './img/watchShots/TimoScheltes_mod.webp', alt: 'Mod by u/TimoScheltes' },
        { src: './img/watchShots/corgTot_mod.webp', alt: 'Mod by u/corgTot' },
        { src: './img/watchShots/heelofthehunt_mod.webp', alt: <><p>Mod by <br/><i className="fa-brands fa-instagram" style={{color: '#ffffff'}}></i>irelandseikomods</p></> },
        { src: './img/watchShots/finemods_mod.webp', alt: 'Mod by u/finemods' },
        { src: './img/watchShots/mods_by_th_mod.webp', alt:  <><p>Mod by <i className="fa-brands fa-instagram" style={{color: '#ffffff'}}></i>mods.by.th</p></>},
        { src: './img/watchShots/OliboyC_mod.webp', alt: 'Mod by u/OliboyC' },
        { src: './img/watchShots/pulseyou_mod.webp', alt: 'Mod by u/pulseyou' }
    ];

    const imagesGrid =[
        { src: './img/watchShots/finemods2_mod.webp', alt: 'Mod by u/finemods' },
        { src: './img/watchShots/jmaerker_mod.webp', alt: 'Mod by u/jmaerker'},
        { src: './img/watchShots/TimoScheltes_mod.webp', alt: 'Mod by u/TimoScheltes' },
        { src: './img/watchShots/spyker1234_mod.webp', alt:'Mod by u/spyker1234'},
        { src: './img/watchShots/corgTot_mod.webp', alt: 'Mod by u/corgTot' },
        { src: './img/watchShots/heelofthehunt_mod.webp', alt: <><p>Mod by <br/><i className="fa-brands fa-instagram" style={{color: '#ffffff'}}></i>irelandseikomods</p></>},
        { src: './img/watchShots/OliboyC_mod.webp', alt: 'Mod by u/OliboyC' },
        { src:'./img/watchShots/munn0014_mod.webp', alt: 'Mod by u/munn0014'},
        { src: './img/watchShots/Junior_Champion4472_mod.webp', alt: 'Mod by u/Junior_Champion4472' },
        { src: './img/watchShots/finemods_mod.webp', alt: 'Mod by u/finemods' },
        { src: './img/watchShots/TimoScheltes2_mod.webp', alt: 'Mod by u/TimoScheltes' },
        { src: './img/watchShots/pulseyou_mod.webp', alt: 'Mod by u/pulseyou' },
        { src: './img/watchShots/sathomasga_mod.webp', alt: 'Mod by u/sathomasga'},
        { src: './img/watchShots/BuddyRich4831_mod.webp' , alt:'Mod by u/BuddyRich4831'},
        { src: './img/watchShots/dekralj2_mod.webp', alt: 'Mod by Dekralj' },
        { src: './img/watchShots/elscdude_mod.webp', alt: 'Mod by u/elscdude'},
        { src: './img/watchShots/Fluorescentomnibus_mod.webp', alt: 'Mod by u/Fluorescentomnibus'},
        { src: './img/watchShots/Emotional_Term7060_mod.webp', alt: 'Mod by u/Emotional_Term7060'},
        { src: './img/watchShots/anon_mod.webp', alt:'Mod by anon'},
        { src: './img/watchShots/Nomenclature916_mod.webp', alt:'Mod by u/Nomenclature916'},
        { src: './img/watchShots/mods_by_th_mod.webp', alt: <><p>Mod by <i className="fa-brands fa-instagram" style={{color: '#ffffff'}}></i> mods.by.th</p></>},
        {src: './img/watchShots/rivlyn_mod.webp', alt: 'Mod by u/rivlyn'}
    ];
    

    const handleClick = () => {
        confetti({
            particleCount: 150,
            spread: 100,
            origin: { y: 0.7 },
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                } else {
                    entry.target.classList.remove('show');
                }
            });
        });

        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));

        return () => {
            hiddenElements.forEach((el) => observer.unobserve(el));
        };
    }, []);


    return (
        <div className="Home">
            <div className="Home-Intro">
                <h1>THE MOST STUNNING WATCH</h1>
                <h3>In the palm of your hand. Created by you.</h3>
            </div>
            <div className="Home-WaveDivider">
                <div class="Home-Wave1">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                    </svg>
                </div>
                <div class="Home-Wave2">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                    </svg>
                </div>

            </div>
            <div className="Home-CarouselWrapper">
                <div className="Home-Socialproof">
                    <p>1.000+ Happy Modders 4,8 &#11088;</p>
                </div>
                <ImageSlider images={images} />
                <div className="Home-Top-Lurker">
                    <h3>WHERE TO START?</h3>
                    <i id="Home-Chevron" className="fa-solid fa-chevron-down" style={{ color: "white" }}></i>
                </div>
            </div>
            <div className="Home-MidSection">
            <i className="fa-solid fa-thumbtack" style={{fontSize: '34px', marginBottom: '-1px'}}></i>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#26223B', color: 'hsl(250, 10%, 95%)', borderLeft: '1px solid white' }}
                        contentArrowStyle={{ borderRight: '7px solid  #26223B' }}
                        iconStyle={{ background: '#26223B', color: 'hsl(250, 10%, 95%)' }}
                        icon={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '26px', color: 'hsl(250, 10%, 95%)', fontFamily: 'monospace' }}>1</div>}
                    >
                        <h3 className="vertical-timeline-element-title">LEARN THE BASICS</h3>
                        <h4 className="vertical-timeline-element-subtitle">with the beginner guide</h4>
                        <div className="Home-Timeline-Content Home-border1">
                            <NavLink style={{ textDecoration: 'none', color: 'hsl(250, 10%, 95%)' }} to="/Guide">BEGINNER GUIDE</NavLink>
                        </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#713960', color: 'hsl(318, 25%, 85%)', borderRight: '1px solid white' }}
                        contentArrowStyle={{ borderRight: '7px solid  #713960' }}
                        iconStyle={{ background: '#713960', color: 'hsl(318, 25%, 85%)' }}
                        icon={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '26px', color: 'hsl(318, 25%, 85%)', fontFamily: 'monospace' }}>2</div>}
                    >
                        <h3 className="vertical-timeline-element-title">GET ALL THE TOOLS</h3>
                        <h4 className="vertical-timeline-element-subtitle">You'll ever need</h4>
                        <div className="Home-Timeline-Content Home-border2">
                            <NavLink style={{ textDecoration: 'none', color: 'hsl(318, 25%, 85%)' }} to="/Toolcheck">TOOL CHECK</NavLink>
                        </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#C15463', color: 'hsl(352, 60%, 25%)', borderLeft: '1px solid white' }}
                        contentArrowStyle={{ borderRight: '7px solid  #C15463' }}
                        iconStyle={{ background: '#C15463', color: '#fff' }}
                        icon={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '26px', color: 'hsl(352, 60%, 20%)', fontFamily: 'monospace' }}>3</div>}
                    >
                        <h3 className="vertical-timeline-element-title">GET THE PARTS</h3>
                        <h4 className="vertical-timeline-element-subtitle">You want for YOUR watch</h4>
                        <div className="Home-Timeline-Content Home-Timeline-GrayedOut">
                            <p>🔒</p>
                        </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work lastTimelineElement"
                        contentStyle={{ background: '#F48A4B', color: 'hsl(22, 90%, 22%)', borderRight: '1px solid white' }}
                        contentArrowStyle={{ borderRight: '7px solid  #F48A4B' }}
                        iconStyle={{ background: '#F48A4B', color: 'hsl(22, 90%, 22%)' }}
                        icon={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '26px', color: 'hsl(22, 90%, 22%)', fontFamily: 'monospace' }}>4</div>}
                    >
                        <h3 className="vertical-timeline-element-title">BUILD THE WATCH</h3>
                        <h4 className="vertical-timeline-element-subtitle">Of your dreams</h4>
                        <div className="Home-Timeline-Content Home-border4" id="confetti-button">
                            <p onClick={handleClick}>🎉🎉🎉🎉🎉</p>
                        </div>
                    </VerticalTimelineElement>
                </VerticalTimeline>
                {/* <div className="Home-Inquiry">
                    <h3>Do you have a mod everyone needs to see?</h3>
                    <p>Send me your mod to</p><NavLink style={{color: 'white' }} to="mailto:modwatchers@gmail.com">modwatchers@gmail.com</NavLink><p> and your mod may be featured on this site!</p>

                </div> */}
                <div className="Home-ATL">
                   <div className="Home-ATL-Text"> 
                   <p className="hidden" id="Home-ATL-Left">Send me your masterpiece! <br/> modwatchers@gmail.com</p>
                   </div>
                <i className="fa-solid fa-arrows-split-up-and-left"></i>
                <div className="Home-ATL-Text"> 
                   <p className="hidden" id="Home-ATL-Right">Send me your masterpiece! <br/> modwatchers@gmail.com</p>
                   </div>
                </div>

                <div className="Home-ImageGrid">
                    <ImageGrid  images={imagesGrid}/>
                </div>

                <div className="Home-Questions">
                    <h3>Do you still have Questions?</h3>
                    <p>Get help fast here:</p>
                    <div><NavLink to="https://www.reddit.com/r/SeikoMods/" style={{ color: 'white' }}><i className="fa-brands fa-reddit-alien"></i></NavLink><NavLink to="https://discord.gg/sq29Pe4YCS" style={{ color: 'white' }}><i className="fa-brands fa-discord"></i></NavLink></div>

                </div>
            </div>
        </div >
    );
}
