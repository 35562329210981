import './css/Guide.css';
import DoubleImage from '../modules/doubleImage';
import GuideCarousel from '../modules/GuideCarousel';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import { Ausklapp } from '../modules/ausklapp.js';
import useWindowSize from '../modules/useWindowSize';

export default function Guide() {
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                } else {
                    entry.target.classList.remove('show');
                }
            });
        });

        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));

        return () => {
            hiddenElements.forEach((el) => observer.unobserve(el));
        };
    }, []);

    const [GuideactiveIndex, GuidesetActiveIndex] = useState(null);

    const GuideHandleListClick = (index) => {
        GuidesetActiveIndex(index);
    };

    const Partimages = [
        '/img/Guide/WatchParts/Guide-PartsOfWatch1.webp',
        '/img/Guide/WatchParts/Guide-PartsOfWatch2.webp',
        '/img/Guide/WatchParts/Guide-PartsOfWatch3.webp',
        '/img/Guide/WatchParts/Guide-PartsOfWatch4.webp',
        '/img/Guide/WatchParts/Guide-PartsOfWatch5.webp'
    ];

    const preloadImages = (imageUrls) => {
        imageUrls.forEach((url) => {
          const img = new Image();
          img.src = url;
        });
      };

    const movements = {
        nh35: {
            name: "Seiko NH35",
            features: ["Date", "Automatic"],
            nonfeatures: ["Weekday"],
            specs: ["41h Power reserve", "21.600 bph", "-20 / +40 sec per day"],
            imgsource1: "/img/Movements/nh35front.webp",
            imgsource2: "/img/Movements/nh35back.webp"
        },
        nh36: {
            name: "Seiko NH36",
            features: ["Date", "Automatic", "Weekday"],
            nonfeatures: [],
            specs: ["41h Power reserve", "21.600 bph", "-20 / +40 sec per day"],
            imgsource1: "/img/Movements/nh36front.webp",
            imgsource2: "/img/Movements/nh36back.webp"
        },
        nh72: {
            name: "Seiko NH72",
            features: ["Skeleton look", "Automatic"],
            nonfeatures: ["Date", "Weekday"],
            specs: ["41h Power reserve", "21.600 bph", "-20 / +40 sec per day"],
            imgsource1: "/img/Movements/nh72front.webp",
            imgsource2: "/img/Movements/nh72back.webp"
        },
        miyota8215: {
            name: "Seiko GMT NH34",
            features: ["Date", "Automatic", "GMT feature"],
            nonfeatures: ["Weekday"],
            specs: ["41h Power reserve", "21.600 bph", "-20 / +40 sec per day"],
            imgsource1: "/img/Movements/gmtnh34front.webp",
            imgsource2: "/img/Movements/gmtnh34back.webp"
        },
        miyota9015: {
            name: "Miyota 9015",
            features: ["Date", "Automatic"],
            nonfeatures: ["Weekday"],
            specs: ["42h Power reserve", "28.800 bph", "-10 / +30 sec per day"],
            imgsource1: "/img/Movements/miyota9015front.webp",
            imgsource2: "/img/Movements/miyota9015back.webp"
        }
    };

    const { width } = useWindowSize();
    const movementImageSize = width < 1000 ? '100px' : '150px';
    const filteredMovements = Object.keys(movements).filter(key => {

        if (width < 600 && key === 'nh36') {
            return false;
        }
        if (width < 900 && key === 'miyota9015') {
            return false;
        }
        return true;
    });



    const firstMovementKey = Object.keys(movements)[0];
    const [selectedMovement, setSelectedMovement] = useState(firstMovementKey);
    const [movtextVisible, setMovtextVisible] = useState(false);

    const handleSelectMovement = (movementKey) => {
        setSelectedMovement(movementKey);
        // setMovtextVisible(false);
    };

    useEffect(() => {
        setTimeout(() => setMovtextVisible(true), 250);
    }, [selectedMovement]);

    useEffect(() => {
        preloadImages(Partimages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    //Guide Carousel
    const slideData = [
        { image: "/img/Guide/Assembly/step1.webp", text: <><p>Let's first take a look at your movement.</p><p>Your movement has holes (marked with red circles) where the pins of your dial need to be inserted.</p></> },
        { image: "/img/Guide/Assembly/step2.webp", text: <><p>Typically, your dial will have more pins than necessary. <br />To determine which pins need to be removed, compare the holes on your movement with the pins on your dial and identify the ones that don’t align.</p></> },
        { image: "/img/Guide/Assembly/step3.webp", text: "Remove the excess pins using a side cutter, or even better, twist them off with pliers to minimize any remaining pin stubs." },
        { image: "/img/Guide/Assembly/step4.webp", text: "Now, file down any remaining pin stubs to ensure that the dial sits flush on the movement." },
        { image: "/img/Guide/Assembly/step5.webp", text: "Align the pins with the corresponding holes and gently press the dial onto the movement. You should hear a satisfying 'click' as it fits into place. Occasionally, a bit of pressure may be needed, but make sure to apply it gently." },
        { image: "/img/Guide/Assembly/step6.webp", text: "Adjust the time so that the date just flips to the next day: Pull the crown out to the second position and rotate it until the date advances to the next day. At this point, the hands are virtually aligned at the 12 o'clock position." },
        { image: "/img/Guide/Assembly/step8.webp", text: "First, take your hour hand and use Rodico or tweezers to position it at the 12 o'clock mark. Carefully press the hour hand onto the movement using the appropriate hand presser (in this case, the silver hand presser with the black tip)." },
        { image: "/img/Guide/Assembly/step9.webp", text: "Check to ensure that the hour hand is positioned correctly. It should be straight and should not make contact with the dial." },
        { image: "/img/Guide/Assembly/step10.webp", text: "Next, take your minute hand and position it at the 12 o'clock mark, just as you did with the hour hand. Ensure that, once installed, the hour hand and minute hand do not touch each other." },
        {
            image: "/img/Guide/Assembly/step11.webp", text: <><p>Finally, gently press the second hand onto the movement using the red hand presser with the golden tip.</p> <p>If the second hand starts moving, it means you’ve installed it correctly. If it does not move, there are two potential issues:</p>
                <li>
                    The movement may be unwound—unscrew the crown (without pulling it out) and start winding the watch.
                </li>
                <li>
                    The second hand might not be properly positioned.
                </li>
                <p>
                    If the second hand moves while adjusting the time, you will need to reinstall it. </p></>
        },
        { image: "/img/Guide/Assembly/step13.webp", text: "Now it's time to remove the temporary pin. Ensure that the temporary pin is in the '0' position, meaning it should not be pulled out. If it is pulled out, the pushable spring will be hidden. In the next step, you will see the location of the spring." },
        { image: "/img/Guide/Assembly/step12.webp", text: "You need to press the spring indicated by the green arrow while simultaneously pulling out the stem. The spring is only visible when the stem is in the '0' position." },
        { image: "/img/Guide/Assembly/step14.webp", text: "Open the bracelet to create more space to work. Some bracelets are held together by screwed-in pins, while others use pushable pins. If a link has an arrow on the inside, it indicates that the pin is pushable in the direction of the arrow." },
        { image: "/img/Guide/Assembly/step15.webp", text: "Use a rubber ball to unscrew the case back. For case backs that are not screw-down, you will need a case knife to carefully lift and remove the back plate." },
        { image: "/img/Guide/Assembly/step16.webp", text: "Now, gently place your movement into the case. Be mindful of the fragile watch hands on the front of the movement, as they can easily break if not inserted with care. Ensure that the movement is aligned correctly to avoid any damage." },
        { image: "/img/Guide/Assembly/step17.webp", text: "Take your stem (one should be provided with your movement) and screw the crown onto it, but not too tightly. Since the stem is typically longer than required, you will need to measure how much needs to be trimmed using a caliper. Measure the distance as shown in the picture to determine the exact length that needs to be cut off." },
        { image: "/img/Guide/Assembly/step18.webp", text: "Cut off the measured amount from the stem. Pro tip: it’s better to cut off a little less than needed rather than too much, as you can always trim more if necessary." },
        { image: "/img/Guide/Assembly/step19.webp", text: "File the end of the stem so that it is flat, ensuring a proper fit for the crown. Once filed, check that the crown fits securely and operates smoothly on the stem." },
        { image: "/img/Guide/Assembly/step20.webp", text: "Verify if your stem has been cut to the correct length. There should be a small gap, allowing for some spring action. When the spring is compressed, it should resemble the left picture, and when not compressed, it should look like the right picture. This indicates the correct length and functionality of the stem." },
        { image: "/img/Guide/Assembly/step21.webp", text: "Controversial tip: I suggest applying a small amount of thin glue on a piece of paper, then lightly dabbing the stem into the glue before screwing the crown on. This can help prevent the crown from loosening or falling off. However, please note that professional watchmakers typically do not use glue to secure the crown to the stem, as it's not considered a standard practice in watchmaking." },
        { image: "/img/Guide/Assembly/step22.webp", text: "After reinserting the stem, it’s time to lubricate the gasket. Place the gasket inside your gasket lubricator, close the lid, and twist it slightly to evenly coat the gasket with lubricant. This helps maintain water resistance and ensures a proper seal." },
        { image: "/img/Guide/Assembly/step23.webp", text: "Close the case back of your watch securely. Make sure it is properly aligned and tightly sealed to maintain the watch's water resistance and protect the movement inside." },
        { image: "/img/Guide/Assembly/step24.webp", text: <><p>Now it's time to set the time and date. Start by pulling the stem out to the second position and set the time to 6 o'clock. This precaution helps prevent damage to the automatic date switching mechanism.</p> <p>Next, move the stem to the first position and adjust the date. If the date changes when you set the time to 6 o'clock, it indicates that it is 6 AM. After setting the date, you can adjust the time to the correct hour.</p>If your watch is not running, wind it by turning the stem to the '0' position and start winding.<p></p></> },
        { image: "/img/Guide/Assembly/step25.webp", text: "Congratulations! You've just assembled your very own watch. Look at how it shines!" }
    ];


    return (
        <div className="Guide">
            <div className="Guide-Intro">
                <h1>THE MOST STUNNING WATCH</h1>
                <h3>In the palm of your hand. Created by you.</h3>
            </div>
            <div className='Guide-Guide'>
                <div className="Guide-Top-Navigation">
                    <ul>
                        <li style={{ color: 'white' }}>Jump to:</li>
                        <li><Link to="section1" smooth={true} duration={500}>Parts of a watch</Link></li>
                        <li><Link to="section3" smooth={true} duration={500}>Different movement types</Link></li>
                        <li><Link to="section4" smooth={true} duration={500}>Building guide</Link></li>
                        <li><Link to="section5" smooth={true} duration={500}>Frequently asked questions</Link></li>
                        <li><Link to="section6" smooth={true} duration={500}>Help</Link></li>
                    </ul>
                </div>

                <div className='Guide-Content'>
                    <section id="section1">
                        <div className="Guide-Wave Guide-W1">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                            </svg>
                        </div>
                        <div className="Guide-Section-Content">

                            <div className="Guide-Sec1-Content">
                                <div className="Guide-Sec1-Content-Text">
                                    <div className="Guide-Sec1-Content-Text-Heading">
                                        <h2 className='Guide-Section-Headings'> PARTS OF A WATCH</h2>
                                    </div>
                                    <div className="Guide-Sec1-Content-Text-Parts">
                                        <ul>
                                            {[' 1. Bezel', ' 2. Watch dial', ' 3. Crown', ' 4. Watch hands', ' 5. Bracelet'].map((item, index) => (
                                                <li
                                                    key={index}
                                                    className={GuideactiveIndex === index ? 'Guide-Sec1-Content-Text-Parts-List-clicked' : ''}
                                                    onClick={() => GuideHandleListClick(index)}
                                                >
                                                    <p>{item} </p>

                                                </li>
                                            ))}

                                        </ul>
                                    </div>
                                </div>
                                <div className="Guide-Sec1-Content-img">
                                    <img
                                        className="Guide-Sec1-Content-img-Main"
                                        src={GuideactiveIndex === null ? '/img/Guide/Guide-PartsOfWatch.webp' : Partimages[GuideactiveIndex]} alt="Part of a watch" />

                                </div>

                            </div>

                        </div>
                    </section>

                    <section id="section3">
                        <div className="Guide-Wave Guide-W2">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                            </svg>
                        </div>
                        <div className='section3-Content'>
                            <h1 className='Guide-Section-Headings' id="text-center">DIFFERENT MOVEMENT TYPES</h1>
                            <h2 className='Guide-section3-SubHeading' id="text-center">Different movements, different features.</h2>
                            <div className="Guide-Movementsection">
                                <div className="Guide-Movementimages">
                                    {filteredMovements.map((key) => (
                                        <div
                                            className='Guide-Movementimages-div hidden'
                                            key={key}
                                            onClick={() => handleSelectMovement(key)}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <DoubleImage
                                                className="Guide-DoubleImage"
                                                width_={movementImageSize}
                                                height_={movementImageSize}
                                                imgsource1={movements[key].imgsource1}
                                                imgsource2={movements[key].imgsource2}
                                            />
                                            <div className={`Guide-Movementimage-Square ${selectedMovement === key ? 'Guide-Movement-Selec' : ''}`}>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {selectedMovement && (
                                    <>
                                        <div className={`Guide-MovementText ${movtextVisible ? `Guide-MovementTextVisible` : ''}`}>
                                            <h2 className='Guide-Section-Headings'>{movements[selectedMovement].name}</h2>
                                            <div className='Guide-Movement-TextWrapper'>
                                                <div className='Guide-Movement-TextWrapper-Top'>
                                                    <div className='Guide-Movement-TextWrapper-Top-Left'>
                                                        {movements[selectedMovement].features.map((feature, index) => (
                                                            <p key={index}> &#43; {feature} <br /></p>
                                                        ))}

                                                    </div>
                                                    <div className='Guide-Movement-TextWrapper-Top-Right'>
                                                        {movements[selectedMovement].nonfeatures.map((nonfeature, index) => (
                                                            <p key={index}> &#45; {nonfeature} <br /></p>
                                                        ))}
                                                    </div>

                                                </div>
                                                <div className='Guide-Movement-TextWrapper-Bottom'>
                                                    {movements[selectedMovement].specs.map((spec, index) => (
                                                        <p key={index}> &#10022; {spec}  </p>
                                                    ))}
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <svg id="wave" viewBox="0 0 1440 300" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stopColor="rgba(152, 4, 206, 1)" offset="0%"></stop><stop stopColor="rgba(255, 179, 11, 1)" offset="100%"></stop></linearGradient></defs><path fill="url(#sw-gradient-0)" d="M0,120L26.7,130C53.3,140,107,160,160,175C213.3,190,267,200,320,175C373.3,150,427,90,480,85C533.3,80,587,130,640,140C693.3,150,747,120,800,125C853.3,130,907,170,960,185C1013.3,200,1067,190,1120,175C1173.3,160,1227,140,1280,130C1333.3,120,1387,120,1440,135C1493.3,150,1547,180,1600,200C1653.3,220,1707,230,1760,235C1813.3,240,1867,240,1920,245C1973.3,250,2027,260,2080,250C2133.3,240,2187,210,2240,195C2293.3,180,2347,180,2400,155C2453.3,130,2507,80,2560,80C2613.3,80,2667,130,2720,145C2773.3,160,2827,140,2880,140C2933.3,140,2987,160,3040,150C3093.3,140,3147,100,3200,85C3253.3,70,3307,80,3360,95C3413.3,110,3467,130,3520,130C3573.3,130,3627,110,3680,90C3733.3,70,3787,50,3813,40L3840,30L3840,300L3813.3,300C3786.7,300,3733,300,3680,300C3626.7,300,3573,300,3520,300C3466.7,300,3413,300,3360,300C3306.7,300,3253,300,3200,300C3146.7,300,3093,300,3040,300C2986.7,300,2933,300,2880,300C2826.7,300,2773,300,2720,300C2666.7,300,2613,300,2560,300C2506.7,300,2453,300,2400,300C2346.7,300,2293,300,2240,300C2186.7,300,2133,300,2080,300C2026.7,300,1973,300,1920,300C1866.7,300,1813,300,1760,300C1706.7,300,1653,300,1600,300C1546.7,300,1493,300,1440,300C1386.7,300,1333,300,1280,300C1226.7,300,1173,300,1120,300C1066.7,300,1013,300,960,300C906.7,300,853,300,800,300C746.7,300,693,300,640,300C586.7,300,533,300,480,300C426.7,300,373,300,320,300C266.7,300,213,300,160,300C106.7,300,53,300,27,300L0,300Z"></path></svg>
                    </section>
                    <section id="section4">
                        <div className="Guide-section4-Content">
                            <div className="Guide-section4-TopNav">
                                <div className="Guide-section4-TopNav-Heading">
                                    <h2 className='Guide-Section-Headings'>Building Guide</h2>
                                </div>
                                <NavLink to="/Toolcheck" style={{ textDecoration: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                                    <div className="Guide-section4-TopNav-ToolRef YDropShadow">
                                        <p>
                                            ToolCheck
                                        </p>
                                        <div>
                                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                        </div>
                                    </div>
                                </NavLink>

                            </div>
                            <div className="Guide-section4-MainContent">
                                <GuideCarousel slides={slideData} />
                            </div>

                        </div>
                    </section>
                    <section className="Guide-section5" id="section5">
                        <div className="Guide-sec5-Wave">
                            <svg id="wave" style={{ transform: 'rotate(180deg)', transition: '0.3s' }} viewBox="0 0 1440 240" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgba(60, 10, 125, 1)" offset="0%"></stop><stop stop-color="rgba(255, 179, 11, 1)" offset="100%"></stop></linearGradient></defs><path style={{ transform: 'translate(0, 0px)', opacity: 1 }} fill='rgb(60, 10, 125)' d="M0,168L40,152C80,136,160,104,240,112C320,120,400,168,480,180C560,192,640,168,720,168C800,168,880,192,960,196C1040,200,1120,184,1200,152C1280,120,1360,72,1440,44C1520,16,1600,8,1680,8C1760,8,1840,16,1920,36C2000,56,2080,88,2160,96C2240,104,2320,88,2400,76C2480,64,2560,56,2640,64C2720,72,2800,96,2880,92C2960,88,3040,56,3120,56C3200,56,3280,88,3360,120C3440,152,3520,184,3600,184C3680,184,3760,152,3840,116C3920,80,4000,40,4080,48C4160,56,4240,112,4320,132C4400,152,4480,136,4560,124C4640,112,4720,104,4800,104C4880,104,4960,112,5040,120C5120,128,5200,136,5280,132C5360,128,5440,112,5520,100C5600,88,5680,80,5720,76L5760,72L5760,240L5720,240C5680,240,5600,240,5520,240C5440,240,5360,240,5280,240C5200,240,5120,240,5040,240C4960,240,4880,240,4800,240C4720,240,4640,240,4560,240C4480,240,4400,240,4320,240C4240,240,4160,240,4080,240C4000,240,3920,240,3840,240C3760,240,3680,240,3600,240C3520,240,3440,240,3360,240C3280,240,3200,240,3120,240C3040,240,2960,240,2880,240C2800,240,2720,240,2640,240C2560,240,2480,240,2400,240C2320,240,2240,240,2160,240C2080,240,2000,240,1920,240C1840,240,1760,240,1680,240C1600,240,1520,240,1440,240C1360,240,1280,240,1200,240C1120,240,1040,240,960,240C880,240,800,240,720,240C640,240,560,240,480,240C400,240,320,240,240,240C160,240,80,240,40,240L0,240Z"></path></svg>
                        </div>
                        <div className="Guide-FAQ">
                            <div>
                                <h3>Frequently asked questions</h3>
                            </div>
                            <div>
                                <Ausklapp question="What tools do I need?" answer={<>
                                    <p>You can look up the tools you will need here: <NavLink to="/Toolcheck" style={{ color: 'white' }}>Toolcheck</NavLink>.</p>
                                    <p>Make sure to check the list before starting.</p>
                                    <p>Happy modding!</p>
                                </>} />
                                <Ausklapp
                                    question="Can I go swimming with my mod?"
                                    answer={
                                        <>
                                            <p>If assembled correctly, your modded watch can be water-resistant. However, we do not recommend swimming with it, as there's a risk of water leakage that could damage the watch.</p>
                                            <p>If you do wish to swim with your modded watch, consider having a local watchmaker perform a pressure test to ensure its water resistance.</p>
                                            <p>Everyday exposure to splashes, such as washing your hands, should not be an issue.</p>
                                        </>} />
                                <Ausklapp
                                    question="What are OEM parts?"
                                    answer={
                                        <>
                                            <p> OEM = original equipment manufacturer</p>
                                            <p>OEM watch parts are authentic replacement components produced by the same manufacturer that made the original parts for the watch.</p>
                                            <p>OEM parts are typically more expensive than third-party alternatives, but they often offer superior quality.</p>
                                        </>
                                    }
                                />
                                <Ausklapp
                                    question="Is modding watches illegal?"
                                    answer={
                                        <>
                                            <p>No, as long as you own the watch and are modifying it for personal use, watch modding is typically legal. This includes replacing parts, customizing the appearance, or altering the functionality of your watch.</p>
                                            <p>However if you're modding a watch and use trademarks, logos, or brand names that are not authorized, you might run into legal issues.</p>
                                        </>
                                    }
                                />

                            </div>

                        </div>
                    </section>
                    <section className="Guide-section6" id="section6">
                        <h3>Do you still have Questions?</h3>
                        <p>Get help fast here:</p>
                        <div><NavLink to="https://www.reddit.com/r/SeikoMods/" style={{ color: 'white' }}><i className="fa-brands fa-reddit-alien"></i></NavLink><NavLink to="https://discord.gg/sq29Pe4YCS" style={{ color: 'white' }}><i className="fa-brands fa-discord"></i></NavLink></div>

                    </section>
                </div>
            </div >
        </div >
    );
}
