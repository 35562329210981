import React from 'react';
import { bundles } from '../data/bundles';
import { toolLookup } from '../data/tools';
import { Link } from 'react-router-dom';
import './css/BundleDisplay.css';
import ReactGA from 'react-ga4';

const verfolgeButton = () => {
    ReactGA.event({
        category: 'User',
        action: 'Clicked Button_Bundle',
        label: 'Bundle Link'
    });
};

function BundleDisplay({ searchID }) {

    const filteredBundles = bundles.filter(bundle => bundle.contains.includes(searchID));

    if (searchID === 187) {
        return (
            <div className="BundleDisplay-Wrapper">
                {bundles.map(bundle => (
                    <div key={bundle.id} className="BundleDisplay-Main">
                        <div className="BundleDisplay-Left">
                            <img src={bundle.imageUrl} alt={bundle.name} />
                            <h2>{bundle.name}</h2>
                        </div>
                        <div className="BundleDisplay-Right">
                            <div>
                                <h3>TOOLS INCLUDED:</h3>
                                {bundle.contains.map(toolID =>
                                (
                                    <li>
                                        🔧 {toolLookup[toolID].name}
                                    </li>
                                )
                                )}
                            </div>
                            <Link to={bundle.AliLink} style={{ color: 'white', textDecoration: 'none' }} onClick={verfolgeButton}>
                                <div className="BundleDisplay-Link">
                                    Link to Bundle *
                                </div>
                            </Link>
                        </div>

                    </div>
                ))}
            </div>
        );
    } else if (filteredBundles.length === 0) {
        return <div className="BundleDisplay-NotAvailable"><p>No bundle found, which includes the Tool '{toolLookup[searchID].name}'</p></div>;
    }

    return (
        <div className="BundleDisplay-Wrapper">
            {filteredBundles.map(bundle => (
                <div key={bundle.id} className="BundleDisplay-Main">
                    <div className="BundleDisplay-Left">
                        <img src={bundle.imageUrl} alt={bundle.name} />
                        <h2>{bundle.name}</h2>
                    </div>
                    <div className="BundleDisplay-Right">
                        <div>
                            <h3>TOOLS INCLUDED:</h3>
                            {bundle.contains.map(toolID =>
                            (
                                <li>
                                    🔧 {toolLookup[toolID].name}
                                </li>
                            )
                            )}
                        </div>

                        <div className="BundleDisplay-Link">
                            <Link to={bundle.AliLink} style={{ color: 'white', textDecoration: 'none' }} onClick={verfolgeButton}>
                                Link to Bundle *
                            </Link>
                        </div>

                    </div>

                </div>
            ))}
        </div>
    );
}

export default BundleDisplay;