import React, { useState, useRef } from "react";
import './css/ausklapp.css';

export const Ausklapp = ({ question, answer }) => {

    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    return (
        <div className="Ausklapp-Wrap">
            <button onClick={() => setIsOpen(!isOpen)}>
                <p>{question}</p>
                <i className={`fa-solid fa-chevron-down  ${isOpen ? "Ausklapp-rotate" : "Ausklapp-keinrotate"}`}></i>
            </button>
            <div
                className="Ausklapp-Answer"
                style={{
                    maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0px",
                    opacity: isOpen ? "1" : "0.5",
                }}
                ref={contentRef}
            >
                <p>{answer}</p>
            </div>
        </div >
    );


};