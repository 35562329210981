import './css/ToolCheck.css';
import { Link } from 'react-router-dom';
import BigCard from './../modules/bigCard';
import { tool } from '../data/tools';

export default function ToolCheck() {

  return (

    <div className="ToolCheck">
      <div className="main">
        <div className='TopText'>
          <div className='TopText-Wrapper'>
            <h1>EVERY TOOL YOU NEED.</h1>
            <h3>To build your first watch.</h3>
          </div>
        </div>
        <svg className='ToolCheck-Wave' viewBox="0 0 1440 240" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgba(13, 19, 33, 1)" offset="0%"></stop><stop stop-color="rgba(24    , 169, 153, 1)" offset="100%"></stop></linearGradient></defs><path fill="url(#sw-gradient-0)" d="M0,24L20,28C40,32,80,40,120,64C160,88,200,128,240,140C280,152,320,136,360,140C400,144,440,168,480,152C520,136,560,80,600,84C640,88,680,152,720,180C760,208,800,200,840,188C880,176,920,160,960,144C1000,128,1040,112,1080,112C1120,112,1160,128,1200,124C1240,120,1280,96,1320,84C1360,72,1400,72,1440,72C1480,72,1520,72,1560,88C1600,104,1640,136,1680,148C1720,160,1760,152,1800,136C1840,120,1880,96,1920,100C1960,104,2000,136,2040,148C2080,160,2120,152,2160,140C2200,128,2240,112,2280,88C2320,64,2360,32,2400,44C2440,56,2480,112,2520,124C2560,136,2600,104,2640,92C2680,80,2720,88,2760,100C2800,112,2840,128,2860,136L2880,144L2880,240L2860,240C2840,240,2800,240,2760,240C2720,240,2680,240,2640,240C2600,240,2560,240,2520,240C2480,240,2440,240,2400,240C2360,240,2320,240,2280,240C2240,240,2200,240,2160,240C2120,240,2080,240,2040,240C2000,240,1960,240,1920,240C1880,240,1840,240,1800,240C1760,240,1720,240,1680,240C1640,240,1600,240,1560,240C1520,240,1480,240,1440,240C1400,240,1360,240,1320,240C1280,240,1240,240,1200,240C1160,240,1120,240,1080,240C1040,240,1000,240,960,240C920,240,880,240,840,240C800,240,760,240,720,240C680,240,640,240,600,240C560,240,520,240,480,240C440,240,400,240,360,240C320,240,280,240,240,240C200,240,160,240,120,240C80,240,40,240,20,240L0,240Z"></path></svg>
        <h3 id="Toolauflistung-head">MANDATORY TOOLS</h3>
        <div className='Toolauflistung'>

          {tool.filter(item => item.mandatory).map(item => (
            <Link style={{ textDecoration: 0 }} to={`/ToolCheck/Tool/${item.id}`}>
              <div className="Tool-ItemCard">
                <img src={item.imageUrl} alt={item.name} />
              </div>
            </Link>
          ))}

        </div>
        <h3 id="Toolauflistung-head">HELPFUL TOOLS</h3>
        <div className='Toolauflistung'>

          {tool.filter(item => !item.mandatory).map(item => (
            <Link style={{ textDecoration: 0 }} to={`/ToolCheck/Tool/${item.id}`}>
              <div className="Tool-ItemCard">
                <img src={item.imageUrl} alt={item.name} />
              </div>
            </Link>
          ))}

        </div>

        <div className='bundleAuflistung' id="bundles">
          <h3 id="bundleAuflistung-Heading">Good bundles</h3>
          <div className='bundleWrapper'>
            <BigCard
              imageUrl="/img/Tools/watchHandkit.webp"
              title="Watch hand tool kit"
              text="Hand presser : Watch working space : Hand pump : Rodico : Small brush : And more"
              color="#B68CB8"
              ProduktLink="https://www.aliexpress.us/item/3256805863243646.html"
            />
            <BigCard
              imageUrl="/img/Tools/bundle_armbandtoolKit500_300.webp"
              title="Watch strap tool kit"
              text="Tweezer : Small screwdriver : Bracelet removal tool : Watch working space : Case back opener : Other tools"
              color="#6461A0"
              ProduktLink="https://www.aliexpress.us/item/3256805941964635.html"
            />

            <BigCard
              imageUrl="/img/Bundles/bundle4.webp"
              title="Case opener kit"
              text="Tweezer : Working space : Watch screwdrivers : Case Knife : And more"
              color="#314CB6"
              ProduktLink="https://www.aliexpress.us/item/3256807300235015.html"
            />
          </div>
          <div className="ToolCheck-ShowAllBundles">
            <Link style={{ textDecoration: 'none' }} to="/ToolCheck/Bundle">
              Show all available Bundles
            </Link>
          </div>
        </div>
      </div>
      <div className="Tool-RefNote">
        <p style={{color: 'white'}}>*This is an affiliate link. If you make a purchase through these links, I may earn a small commission at no extra cost to you</p>
      </div>
    </div>
  );
}


