import React, { useState, useEffect } from 'react';
import './css/CookieConsent.css'
import Cookies from 'js-cookie';

export default function CookieConsent({ BannerText, acceptBtnText, declineBtnText }) {

    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const consent = Cookies.get('cookieConsent');
        if (!consent) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookieConsent', 'accepted', { expires: 365 });
        setShowBanner(false);
    }
    const handleDecline = () => {
        Cookies.set('cookieConsent', 'declined', { expires: 365 });
        setShowBanner(false);
    };


    if (!showBanner) return null;

    return (
        <div className="CookieConsent-ContentBlocker">
            <div className="CookieConsent-Main">
                <div>
                    {BannerText}
                </div>
                <div className="CookieConsent-Buttons">
                    <button onClick={handleAccept}>{acceptBtnText}</button>
                    <button onClick={handleDecline}>{declineBtnText}</button>
                </div>
            </div>
        </div>
    );

};