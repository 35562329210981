import './footer.css';
import React from 'react';
import HoverVideo from '../modules/HoverVideo.js'
import { Link } from 'react-router-dom';

function Footer() {

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section about">
                    <h2 className="footer-title">About Us</h2>
                    <p>
                        ModWatchers was created to help everyone <br />craft a timepiece worth admiring.
                    </p>
                    <p>
                        If you have questions about the website, <br />feel free to contact me : modwatchers@gmail.com
                    </p>
                </div>
                <div className="footer-section links">
                    <h2 className="footer-title">Legal</h2>
                    <ul>
                        <li><Link to="/imprint">Imprint</Link></li>
                        <li><Link to="/imprint">Privacy Policy</Link></li>
                        <li><Link to="/imprint">Terms and conditions</Link></li>
                    </ul>
                </div>
                <div className="footer-section social">
                    <h2 className="footer-title">Made by</h2>
                    <div className="social-links">
                        <HoverVideo
                            sauce="/img/signed.mp4"
                            width_="180px"
                        />
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 modWatchers. All rights reserved.</p>
            </div>
        </footer>

    );

}

export default Footer;