import './header.css';
import { NavLink } from 'react-router-dom';

function Header() {

    return (
        <nav className="Header-Nav">
            <NavLink to="/">
                <img id="logo" src="/img/templogo.png" alt="" /></NavLink>
            <input type="checkbox" id="toggle_button" />
            <label htmlFor="toggle_button" className="toggle_button">
                <div className="bar" id="bar1"></div>
                <div className="bar" id="bar2"></div>
                <div className="bar" id="bar3"></div>
            </label>
            <ul>
                <li>
                    <NavLink to="/Guide"><p className='Nav-selecText'>Beginner Guide</p></NavLink>
                </li>
                <li>
                    <NavLink to="/ToolCheck"><p className='Nav-selecText'>Tool Check</p></NavLink>
                </li>
                <li><NavLink to="/under_construction"><img id="myWatchesIcon" src="/img/myWatchesIcon.png" alt="My watches" />(0)</NavLink></li>
            </ul>
        </nav>



    );

}

export default Header;