export const tool = [
    {
        id: 1,
        name: 'Hand remover',
        description: 'The hand remover helps to remove the watch hands safely without risking damage to the watch dial or hands.',
        imageUrl: '/img/Tools/handRemover.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_DD6ERDh',
        linkAmazon: 'https://amzn.to/3zjdmSC',
        bundleLink: '',
        mandatory: true
    },
    {
        id: 2,
        name: 'Hand presser',
        description: 'A hand setter is used to precisely attach the watch hands onto the movement, ensuring they are correctly positioned.',
        imageUrl: '/img/Tools/handSetter600b400h.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_Dd528qX',
        linkAmazon: 'https://amzn.to/3z8gbpL',
        bundleLink: '',
        mandatory: true
    },
    {
        id: 3,
        name: 'Caliper',
        description: 'The caliper lets you measure how much of the stem needs to be cut. It\'s a great tool to have at home',
        imageUrl: '/img/Tools/Caliper600b400h.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_Dcu2Smf',
        linkAmazon: 'https://amzn.to/3ZpyPE1',
        bundleLink: '',
        mandatory: true
    },
    {
        id: 4,
        name: 'Finger protection',
        description: 'Prevents the transfer of oils and sweat to the delicate parts of the watch, which can cause corrosion or damage over time.',
        imageUrl: '/img/Tools/FingerProtection.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_DENLXlp',
        linkAmazon: 'https://amzn.to/4gq5bEJ',
        bundleLink: '',
        mandatory: true
    },
    {
        id: 5,
        name: 'Gasket Lubricant',
        description: 'Gasket lubricant protects against wear and premature aging caused by friction, heat, and chemical exposure, and helps to ensure a tight seal to prevent leaks.',
        imageUrl: '/img/Tools/gasketLubricant.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_DduKryr',
        linkAmazon: 'https://amzn.to/3XsrCAw',
        bundleLink: '',
        mandatory: true
    },
    {
        id: 6,
        name: 'File / Sand paper',
        description: 'When adjusting the stem to the appropriate length, it is crucial to file down the end to ensure a proper fit for the crown, enhancing both function and aesthetics.',
        imageUrl: '/img/Tools/sandpaper.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_De5q5xl',
        linkAmazon: 'https://amzn.to/3AZcoLM',
        bundleLink: '',
        mandatory: true
    },
    {
        id: 7,
        name: 'Rubber Ball',
        description: 'The rubber ball assists in opening the screw-down back of a watch, allowing to remove it without scratching or damaging it.',
        imageUrl: '/img/Tools/ball.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_DnOAf5Z',
        linkAmazon: 'https://amzn.to/4glPhLL',
        bundleLink: '',
        mandatory: true
    },
    {
        id: 8,
        name: 'Side cutter',
        description: 'The side cutter is used to cut not needed pins at the back of the dial and to cut down the stem to the correct size.',
        imageUrl: '/img/Tools/seitenschneider.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_Dn4BUM7',
        linkAmazon: 'https://amzn.to/3N58nIN',
        bundleLink: '',
        mandatory: true
    },
    {
        id: 9,
        name: 'Tweezer',
        description: 'The most important tool to have. A tool to arrange very small parts. Like the parts of a watch.',
        imageUrl: '/img/Tools/pinzette.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_DnKJERv',
        linkAmazon: 'https://amzn.to/3zkaTHO',
        bundleLink: '',
        mandatory: true
    },
    {
        id: 10,
        name: 'Hand pump',
        description: 'You dont want dust or other small particles in your watch. Hence a hand pump is an easy way to get rid of it.',
        imageUrl: '/img/Tools/pumpe.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_Dnppgqn',
        linkAmazon: 'https://amzn.to/4dZUI1q',
        bundleLink: '',
        mandatory: true
    },
    {
        id: 11,
        name: 'Link removal tool',
        description: 'Important note: some bracelets are screwn down instead of having a pushabel pin. I suggest getting a kit with all tools to be safe.',
        imageUrl: '/img/Tools/armbandtool.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_DFMRJgX',
        linkAmazon: 'https://amzn.to/4d4yBp1',
        bundleLink: '',
        mandatory: false
    },
    {
        id: 12,
        name: 'Working space',
        description: 'You need a working space to lay down the watch, you dont want to lay down the movement on a hard and dirty surface.  ',
        imageUrl: '/img/Tools/workspace.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_Ddve5K7',
        linkAmazon: 'https://amzn.to/4gruXsg',
        bundleLink: '',
        mandatory: false
    },
    {
        id: 13,
        name: 'Magnifying glas',
        description: 'A magnifying glass enhances visibility, making it easier to perform precise tasks like adjusting, repairing, or assembling the watch components accurately.',
        imageUrl: '/img/Tools/lupe.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_Ddoqt9p',
        linkAmazon: 'https://amzn.to/3z9kBwC',
        mandatory: false
    },
    {
        id: 14,
        name: 'Watch screwdriver',
        description: 'A watch screwdriver is essential for working with watches because it is specifically designed for handling the tiny screws found in watch movements and cases.',
        imageUrl: '/img/Tools/screwdriver.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_DFithij',
        linkAmazon: 'https://amzn.to/3TtuLie',
        mandatory: true
    },
    {
        id: 15,
        name: 'Case Knife',
        description: 'A case knife is a specialized tool used for opening snap-off watch case backs.',
        imageUrl: '/img/Tools/spatula.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_DmfpBLz',
        linkAmazon: 'https://amzn.to/3Tu65X8',
        mandatory: false
    },
    {
        id: 16,
        name: 'Rodico',
        description: 'Rodico is a putty-like material used in watchmaking for lifting small parts, holding tiny components in place, cleaning watch movements, and removing fingerprints or dust from delicate surfaces',
        imageUrl: '/img/Tools/gummiteil.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_DdqaHxz',
        linkAmazon: 'https://amzn.to/4d8fH0F',
        mandatory: true
    },
    {
        id: 17,
        name: 'Movement holder',
        description: 'A movement holder securely holds the movement in place, preventing damage to delicate components during assembly and adjustments.',
        imageUrl: '/img/Tools/movementHolder.webp',
        linkAlibaba: 'https://s.click.aliexpress.com/e/_DdoKVI3',
        linkAmazon: 'https://amzn.to/4dZXe7D',
        mandatory: false
    }
];

export const toolLookup = tool.reduce((acc, tool) => {
    acc[tool.id] = tool;
    return acc;
}, {});