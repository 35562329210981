import React from 'react';
import './css/bigCard.css';
import { NavLink } from 'react-router-dom';

const BigCard = ({ imageUrl, title, text, color, ProduktLink }) => {
    const words = text.split(':');
    return (
        <div className='bigCard' style={{ border: `3px solid ${color}` }}>
            <div className='bigCard-image'>
                <img src={imageUrl} alt={title} />
            </div>
            <div className='bigCard-bottomtext'>
                <h3>{title}</h3>
                <div className="bigCard-Auflistung">
                    <h4>includes:</h4>
                    <div className='bigCard-List-Link-Seperator'>
                        <div className='bigCard-List-Wrapper'>
                            <ul>
                                {words.map((word, index) => (
                                    <li key={index}>{word}</li>
                                ))}
                            </ul>
                        </div>
                        <div className='bigCard-Link-Wrapper'>
                            
                            <NavLink to={ProduktLink} className="bigCard-Link">
                            Available here*<i className="fa-solid fa-link"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default BigCard;
