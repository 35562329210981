import './css/Bundle.css';
import { useParams, Link } from 'react-router-dom';
import { toolLookup } from '../data/tools';
import BundleDisplay from '../modules/BundleDisplay';

export default function Bundle() {

    const { id } = useParams();
    const tool = toolLookup[id];

    if (id === undefined) {
        return (
            <div className="Bundle-Main">

                <h2 id="Bundle-Heading" > All available bundles:  </h2>
                <div className="Bundle-LinkWrapper">
                    <Link style={{ textDecoration: 'none' }} to={`/ToolCheck/`}>
                        <div className="Tool-Backarrow" id="bundle-Ar"><i class="fa-solid fa-arrow-left" style={{ color: '#ffffff' }}></i>   Go Back</div>
                    </Link>
                </div>

                <div className="Bundle-Display">
                    <BundleDisplay searchID={187} />
                </div>

                <div className="Bundle-RefNote">
                    <p>*This is an affiliate link. If you make a purchase through these links, I may earn a small commission at no extra cost to you</p>
                </div>

            </div>
        );
    }

    if (!tool) {
        return (
            <div className="Tool-NotExists"><p>The tool you are looking for does not exist.</p> <Link style={{ color: "white" }} to="/Toolcheck/"> Bring me back</Link></div>
        );
    }



    return (
        <div className="Bundle-Main">

            <h2 id="Bundle-Heading" > Bundles with '{tool.name}' </h2>
            <div className="Bundle-LinkWrapper">
                <Link style={{ textDecoration: 'none' }} to={`/ToolCheck/`}>
                    <div className="Tool-Backarrow"><i className="fa-solid fa-arrow-left" style={{ color: '#ffffff' }}></i>   Go Back</div>
                </Link>
                <Link style={{ textDecoration: 'none', color: 'white' }} to={'/ToolCheck/Bundle/'}>
                    Show all bundles
                </Link>
            </div>

            <div className="Bundle-Display">
                <BundleDisplay searchID={id} />
            </div>

            <div className="Bundle-RefNote">
                <p>*This is an affiliate link. If you make a purchase through these links, I may earn a small commission at no extra cost to you</p>
            </div>

        </div>
    );

}