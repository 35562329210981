import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/ImageSlider.css';


const width = window.innerWidth;
var imagewidth = 250;
if(width < 800){
    imagewidth = 200;
}
var imageCount;
if(width < 600){
    imageCount = 1;
}else if(width <800){
    imageCount = 2;

}else if(width < 1000){
    imageCount = 4;
}else{
    imageCount = (width - 150) / imagewidth;
}
const slideAmount = Math.floor(imageCount / 3 + 1);


const MultiImageCarousel = ({ images }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        autoplay: true,
        autoplaySpeed: 3500,
        slidesToShow: imageCount, // Anzahl der sichtbaren Bilder
        slidesToScroll: slideAmount, // Anzahl der Bilder, die pro Klick gescrollt werden
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div className='imageWrapper' key={index}>
                        <img src={image.src} alt={image.alt} className="carousel-image" />
                        <p className='imageText'>{image.alt}</p>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default MultiImageCarousel;
